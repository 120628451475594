// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-js": () => import("./../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-alerts-js": () => import("./../src/templates/alerts.js" /* webpackChunkName: "component---src-templates-alerts-js" */),
  "component---src-templates-contact-js": () => import("./../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-corporate-js": () => import("./../src/templates/corporate.js" /* webpackChunkName: "component---src-templates-corporate-js" */),
  "component---src-templates-events-js": () => import("./../src/templates/events.js" /* webpackChunkName: "component---src-templates-events-js" */),
  "component---src-templates-gallery-js": () => import("./../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-index-js": () => import("./../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-privacy-js": () => import("./../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-reviews-js": () => import("./../src/templates/reviews.js" /* webpackChunkName: "component---src-templates-reviews-js" */),
  "component---src-templates-services-js": () => import("./../src/templates/services.js" /* webpackChunkName: "component---src-templates-services-js" */),
  "component---src-templates-staff-js": () => import("./../src/templates/staff.js" /* webpackChunkName: "component---src-templates-staff-js" */)
}

