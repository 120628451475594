import React from "react"

let LocaleContext
const { Provider } = (LocaleContext = React.createContext(""))
// use context as a hook
export const useLocaleContext = () => React.useContext(LocaleContext)

export default function LocaleContextProvider({ children }) {
  const [locale, setLocale] = React.useState("")

  React.useEffect(() => {
    const w = typeof window !== "undefined" ? window : ""
    const lang = w.localStorage.getItem("sanalife-lang")
    setLocale(lang ?? "")
  }, [])

  return <Provider value={{ locale, setLocale }}>{children}</Provider>
}
